import { AfterViewInit, ApplicationRef, Component, HostBinding, HostListener, isDevMode, ViewContainerRef } from '@angular/core'
import { RouterModule } from '@angular/router'
import { debounceTime, distinctUntilChanged } from 'rxjs'
import { LongtailService } from '@nx-superprep/backend/firebase'
import { BreakpointService, OrientationService, UpdateService } from '@nx-superprep/shared'
import { SmartBannerPlatform, SmartBannerService } from '@nx-superprep/smart-banner'
import { log } from '@nx-superprep/utils'

@Component({
  standalone: true,
  selector: 'popstudy2-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [RouterModule],
})
export class AppComponent implements AfterViewInit {
  title = 'AppComnponent'
  window: (Window & typeof globalThis) | undefined
  // safe area
  @HostBinding('style.padding-top') paddingTop = '0px'
  @HostBinding('style.padding-bottom') paddingBottom = '0px'
  @HostBinding('style.padding-left') paddingLeft = '0px'
  @HostBinding('style.padding-right') paddingRight = '0px'

  @HostListener('window:touchstart', ['$event']) onTouchStart(): void {
    if (!this.breakpointService.isHandset) {  return }
    this.orientationService.lockOrientation('portrait-primary').then((success) => {
      if (success && window && 'visualViewport' in window) {
        // Apply safe area insets using CSS environment variables
        this.paddingTop = `env(safe-area-inset-top, 0px)`
        this.paddingBottom = `env(safe-area-inset-bottom, 0px)`
        this.paddingLeft = `env(safe-area-inset-left, 0px)`
        this.paddingRight = `env(safe-area-inset-right, 0px)`
      }
    })
  }

  constructor(
    protected appRef: ApplicationRef,
    protected updateService: UpdateService,
    protected longtailService: LongtailService,
    protected breakpointService: BreakpointService,
    protected orientationService: OrientationService,
    private readonly smartBannerService: SmartBannerService,
    private readonly viewContainerRef: ViewContainerRef,
  ) {
    log.debug(window.navigator.userAgent)
  }

  ngAfterViewInit() {
    if (isIOSSafari()) { document.body.classList.add("mobile-safari") } else { document.body.classList.remove("mobile-safari") }
    this.updateService.check().then((updated) => {
      if (updated) { this.longtailService.logAppUpdate()} else { this.longtailService.logAppOpen('AppComponent')}
    })
    this.smartBannerService.initialize({
      viewContainerRef: this.viewContainerRef,
      title: 'PopStudy',
      author: 'Longtail Education',
      price: 'Free',
      priceSuffix: {ios: ' - On the App Store', android: ' - In Google Play'},
      icon: {ios: "/assets/icons/apple-app-store.svg", android: "/assets/icons/google-play.svg"},
      closeLabel: 'Close',
      buttonUrl: { ios: "https://apps.apple.com/us/app/longtail-education/id1169672977?mt=8", android: 'https://popstudy.conm'},
      enabledPlatforms: [SmartBannerPlatform.IOS],
      daysHidden: 15,
      daysReminder: 90,
      hideRating: true,
    })
    if (typeof Worker !== 'undefined') {
      // Create a new
      const worker = new Worker(new URL('./offline.worker', import.meta.url));
      worker.onmessage = ({ data }) => {
        console.log(`page got message ${data}`);
      };
      worker.postMessage('hello');
    } else {
      console.log('no worker')
    }
    if (isDevMode()) {
      this.appRef.isStable.pipe(
        debounceTime(200),
        distinctUntilChanged(),
      ).subscribe(it => {
        //log.debug('isStable', it)
      })
    }
  }
}

function isIOSSafari() {
  const ua = window.navigator.userAgent
  const iOS = ua.match(/iPad/i) || ua.match(/iPhone/i)
  const webkit = ua.match(/WebKit/i)
  const iOSSafari = iOS && webkit && !ua.match(/CriOS/i)
  return iOSSafari
}
