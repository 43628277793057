import { Injectable, isDevMode } from '@angular/core'
import { AuthService } from './auth.service'
import { generateAnonymousEmail, generatePassword, log, tzguess } from '@nx-superprep/utils'
import { BehaviorSubject, Subscription, firstValueFrom, from, of, tap } from 'rxjs'
import { HeaderTemplateValues, User } from '../models/front-end'
import { ClassroomJoinRequest } from '../models/back-end'
import { EventParams, getAnalytics, logEvent } from '@angular/fire/analytics'

export type AuthState = {
  step?:'init'|'auth'|'update'|'reset'|'join'|'post',
  success:boolean,
  message:string,
  data?:any
}

@Injectable({providedIn: 'root'})
export class UserService extends AuthService {

  private _analytics = getAnalytics()

  private get analytics() {
    return this._analytics || getAnalytics()
  }
  private set analytics(value) {
    this._analytics = value || getAnalytics()
  }

  logSignup(eventParams?: EventParams) {
    logEvent(this.analytics, 'sign_up', eventParams)
  }

  logLogin(eventParams?: EventParams) {
    logEvent(this.analytics, 'login', eventParams)
  }

  private _subscription: Subscription|undefined
  private _headerTemplateValues = HeaderTemplateValues.slice(0,3)
  private _status = new BehaviorSubject<AuthState>({step:'init', success:true, message:'', data:undefined})

  get status() { return this._status}

  get isGuest() { return Boolean(this.activeUser?.isAnonymous) }

  get isContentManager() {
    return !!this.appSetting?.contentReviewMode
  }
  get isDebugMode() {
    return !!this.appSetting?.debugMode
  }

  get headerTemplateValues() {
    return this._headerTemplateValues
  }

  get isUserActive() {
    return !!this.activeUser
  }

  get activeUser() {
    return this.currentUserData
  }

  private _classroomId: ClassroomJoinRequest |undefined
  get classroomId() {
    return this._classroomId
  }

  get isLoggedIn() {
    return this.activeUser
    ? of(true)
    : from(super.signInTemporary()
    .then((credential) => {
      if (credential) {
        this.currentUserData = credential.user as User
        this.onSignIn(this.currentUserData)
      }
      return !!credential
    }))
  }

  // main sign in/up entry point classroom id
  async signInWithClassroomId(arg: {
    anonymous: boolean
  } | {
    register?: boolean,
    username: string,
    password: string,
    email?: string,
    firstName?: string,
    lastName?: string,
    classroomId?: ClassroomJoinRequest,
  }) {
    await this.signOut(!('anonymous' in arg))
    const register = Boolean(('anonymous' in arg) ? true : arg.register)
    const classroomId = ('classroomId' in arg) && (arg.classroomId?.code ?? '').length > 0 ? arg.classroomId : undefined
    const username = ('anonymous' in arg) ? generateAnonymousEmail() : arg.username
    const password = ('anonymous' in arg) ? generatePassword() : arg.password
    const credential = await (register ? this.signUp(username, password) : this.signIn(username, password))
    this.status?.next({step: 'auth', success: !!credential, message: register ? 'signed up' : 'signed in', data: credential})
    if (!credential) { return }
    log.debug(credential)
    const eventParams = { method: classroomId ? 'join_classroom' : 'firebase', classroomId }
    if (register) { this.logSignup(eventParams) } else { this.logLogin(eventParams) }
    const profileName = ('anonymous' in arg) ? 'Guest' : `${arg.firstName} ${arg.lastName}`
    let user: User|undefined = ('anonymous' in arg ? {...credential.user, password, firstName: 'Guest', lastName: 'User', profileName: 'GU'} : {...credential.user, firstName: arg.firstName, lastName: arg.lastName, profileName}) as User
    user = await this.postSignIn(user)
    this.status?.next({step: 'post', success: !!user, message: 'auth', data: user})
    if (user) {
      this.userData.next(user)
      if (classroomId) {
        try {
          const _result = await firstValueFrom(this.joinClassroom(classroomId))
          const success = _result.statusCode===200
          this.status?.next({step: 'join', success, message: 'joining class', data: classroomId})
          log.debug(`${success ? 'success' : 'failure' } joining classroom ${classroomId.code}`, _result)
          if (success) { this._classroomId = classroomId}
        } catch (error) {
          this.status?.next({step: 'join', success: false, message:(error as Error).message, data: classroomId})
          log.debug(`error joining classroom ${classroomId.code}`, error)
        }
      }
    }
    return user
  }

  async update(user: User, arg: {
    username: string,
    password: string,
    email: string,
    currentPassword: string,
    firstName: string,
    lastName: string,
  }) {
    log.debug(user, arg)
    let updated: User|undefined
    updated = await this.changeEmail(arg.email, arg.currentPassword)
    if (!updated) { return }
    updated = await this.changePassword(arg.password, arg.currentPassword)
    if (!updated) { return }
    updated.isConversion = true
    const timeZone = tzguess()
    const profileName = `${arg.firstName} ${arg.lastName}`
    await this.setInfo({ firstName: arg.firstName, lastName: arg.lastName, timeZone, profileName})
    return updated
  }

  override onSignIn(user: User) {
    super.onSignIn(user)
    this.status?.next({step: 'auth', success: !!user.uid, message: user.uid ? 'success' : 'failed', data: user})
    if (!user.uid) { return }
    this._subscription?.unsubscribe()
    // logged out state
    if (!this.auth.currentUser) { return }
    this._subscription = this.getAppSetting(user.uid)
    .pipe(
      tap(() => {this._headerTemplateValues = HeaderTemplateValues.slice(0, this.isContentManager ? isDevMode() ? 5 : 5 : 3)})
    )
    .subscribe()
  }
  override onSignOut(user: User) {
    super.onSignOut(user)
    this._subscription?.unsubscribe()
    this.status?.next({step: 'init', success:true, message: 'signed out'})
    this._headerTemplateValues = HeaderTemplateValues.slice(0, 3)
  }
}
